import axios from "axios";

const API_URL = "/api/savedartists/";

// Create new saved artist
const createSavedArtist = async (savedArtistData, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.post(API_URL, savedArtistData, config);

  return response.data;
};

// Get user's saved artists
const getSavedArtists = async (token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.get(API_URL, config);

  return response.data;
};

// Delete saved artist
const deleteSavedArtist = async (savedArtistId, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.delete(API_URL + savedArtistId, config);

  return response.data;
};

// Update user saved artist
// Don't think this works
/*
const updateGoal = async (goalData, goalId, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.put(API_URL + goalId, goalData, config);

  return response.data;
};
*/

const savedArtistService = {
  createSavedArtist,
  getSavedArtists,
  deleteSavedArtist,
};

export default savedArtistService;
