import React from "react";
import Popup from "reactjs-popup";
import { FaExpandAlt, FaEdit } from "react-icons/fa";
import SongEditForm from "./SongEditForm";

const SongItem = (props) => {
  const actualUrl = props.song.audioUrl + ".wav";
  return (
    <div>
      <p>
        Song {props.song.songNumber}: "{props.song.name}"
      </p>
      <Popup
        modal
        nested
        trigger={
          <button
            type="button"
            style={{
              fontSize: "15px",
              background: "none",
              border: "solid 1px",
              margin: "5px 5px 5px 5px",
              cursor: "pointer",
              borderRadius: "3px",
            }}
          >
            Edit Song
            <FaEdit />
          </button>
        }
        position="right center"
        //className="my-popup"
      >
        <div className="">
          <p>Edit Song</p>
          <SongEditForm song={props.song} goal={props.goal} />
        </div>
      </Popup>
      <h4>ISRC: {props.song.isrc}</h4>
      <h4>Artist(s): {props.song.songArtist}</h4>
      {props.song.artists &&
        props.song.artists[0] &&
        props.song.artists.map((art) => (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
            }}
          >
            <h3 key={art.artistName}>{art.artistName} </h3>
            <Popup
              trigger={
                <button
                  type="button"
                  style={{
                    fontSize: "15px",
                    background: "none",
                    border: "solid 1px",
                    margin: "5px 5px 5px 5px",
                    cursor: "pointer",
                    borderRadius: "3px",
                  }}
                >
                  Show Artist Details
                  <FaExpandAlt />
                </button>
              }
              position="right center"
              //className="my-popup"
            >
              <div className="">
                <p>
                  <span style={{ fontWeight: "bold" }}>Artist Name:</span>{" "}
                  {art.artistName}
                </p>
                <p>
                  <span style={{ fontWeight: "bold" }}>Artist Role:</span>{" "}
                  {art.role}
                </p>
                <p>
                  <span style={{ fontWeight: "bold" }}>Spotify URI:</span>{" "}
                  {art.spotifyURI}
                </p>
                <p>
                  <span style={{ fontWeight: "bold" }}>Apple ID:</span>{" "}
                  {art.appleId}
                </p>
              </div>
            </Popup>
          </div>
        ))}
      <h3>Writers:</h3>
      {props.song.writers.length == 0 && <h4>None</h4>}
      {props.song.writers &&
        props.song.writers[0] &&
        props.song.writers.map((writer) => (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
            }}
          >
            <h4 key={writer.writerName}>{writer.writerName} </h4>
          </div>
        ))}
      <h3>Producers:</h3>
      {props.song.producers.length == 0 && <h4>None</h4>}
      {props.song.producers &&
        props.song.producers[0] &&
        props.song.producers.map((producer) => (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
            }}
          >
            <h4 key={producer.producerName}>{producer.producerName} </h4>
          </div>
        ))}
      <h4>Start Time for Tiktok: {props.song.previewStartTime}</h4>
      <h4>Language: {props.song.language}</h4>
      <h4>Explicit: {props.song.explicit ? "Y" : "N"}</h4>
    </div>
  );
};

export default SongItem;
