import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import GoalForm from "../components/GoalForm";
import GoalItem from "../components/GoalItem";
import Spinner from "../components/Spinner";
import { getGoals, reset } from "../features/goals/goalSlice";

function Create() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [showPending, setShowPending] = useState(false);

  const { user } = useSelector((state) => state.auth);
  const { goals, isLoading, isError, message } = useSelector(
    (state) => state.goals
  );
  console.log(goals);
  const pendingAlbums = goals.filter(
    (album) => album.status === "pending approval by team"
  );

  useEffect(() => {
    if (isError) {
      console.log(message);
      //localStorage.clear();
    }

    if (!user) {
      /*setTimeout(() => {
        console.log("World!");
      }, 5000);*/
      navigate("/login");
      console.log("yeetDash");
    }

    if (user && user.role == "admin") {
      dispatch(getGoals());
    }

    return () => {
      dispatch(reset());
    };
  }, [user]);

  if (!user) {
    return <p>Your session has expired. Please log back in.</p>;
  }

  if (user && user.isApproved == "false") {
    return (
      <p>
        Your account must be approved by a moderator before you can upload
        albums.
      </p>
    );
  }

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <div className="dash">
      {user && user.role == "admin" && (
        <>
          <section className="heading">
            {/*<h5>Welcome {user && user.name}</h5>*/}
            <p>Submit an Album</p>
          </section>
          <GoalForm user={user} />
        </>
      )}
    </div>
  );
}

export default Create;
