import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Header from "./components/Header";
import Dashboard from "./pages/Dashboard";
import Login from "./pages/Login";
import Register from "./pages/Register";
import RegisterAdmin from "./pages/RegisterAdmin";
import RegisterArtist from "./pages/RegisterArtist";
import Accounts from "./pages/Accounts";
import Albums from "./pages/Albums";
import Messages from "./pages/Messages";
import Settings from "./pages/Settings";
import Overview from "./pages/Overview";
import Create from "./pages/Create";

function App() {
  return (
    <>
      <Router>
        <div className="container">
          <Header />
          <Routes>
            <Route path="/" element={<Dashboard />} />
            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<Register />} />
            <Route path="/accounts" element={<Accounts />} />
            <Route path="/register-admin" element={<RegisterAdmin />} />
            <Route path="/register-member" element={<RegisterArtist />} />
            <Route path="/albums" element={<Albums />} />
            <Route path="/messages" element={<Messages />} />
            <Route path="/settings" element={<Settings />} />
            <Route path="/overview" element={<Overview />} />
            <Route path="/create" element={<Create />} />
          </Routes>
        </div>
      </Router>
      <ToastContainer />
    </>
  );
}

export default App;
