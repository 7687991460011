import { useState, useEffect } from "react";
import { FaSignInAlt } from "react-icons/fa";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { login, reset } from "../features/auth/authSlice";
import Spinner from "../components/Spinner";

function Login() {
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });

  const [width, setWidth] = useState(window.innerWidth);
  const isMobile = width <= 768;

  const { email, password } = formData;

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { user, isLoading, isError, isSuccess, message } = useSelector(
    (state) => state.auth
  );

  useEffect(() => {
    if (isError) {
      toast.error(message);
    }

    if (isSuccess || user) {
      navigate("/");
      console.log("yeetLog");
    }

    dispatch(reset());
  }, [user, isError, isSuccess, message, navigate, dispatch]);

  useEffect(() => {
    if (isMobile) {
      alert(
        "For the best experience, we recommend accessing our website on a computer rather than a mobile device."
      );
    }
  }, []);

  const onChange = (e) => {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const onSubmit = (e) => {
    e.preventDefault();

    const userData = {
      email,
      password,
    };

    dispatch(login(userData));
  };

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <div
      style={{
        background: "linear-gradient(to bottom right, #427bf5, #bc42f5)",
        height: "100vh",
        marginTop: "-60px",
        paddingTop: "140px",
      }}
    >
      <div className="login-thing">
        <section className="heading">
          {/*<h1>
            <FaSignInAlt />
            Login
          </h1>*/}
          <div className="logo" style={{ paddingTop: "30px" }}>
            <img
              src="https://thesystemdistributioninterface.s3.amazonaws.com/5349e55effeb26e6601507ecb69be4b8"
              width="300px"
            />
          </div>
          <p>Login and start creating albums</p>
        </section>
        <section className="form">
          <form onSubmit={onSubmit}>
            <div className="form-group">
              <label htmlFor="text">
                Email <span style={{ color: "#bd0000" }}>*</span>
              </label>
              <input
                type="email"
                className="form-control"
                id="email"
                name="email"
                value={email}
                placeholder="Enter your email"
                onChange={onChange}
              />
            </div>
            <div className="form-group">
              <label htmlFor="text">
                Password <span style={{ color: "#bd0000" }}>*</span>
              </label>
              <input
                type="password"
                className="form-control"
                id="password"
                name="password"
                value={password}
                placeholder="Enter your password"
                onChange={onChange}
              />
            </div>
            <div className="form-group">
              <button
                type="submit"
                className="btn btn-block"
                style={{
                  backgroundColor: "#bc42f5",
                  border: "none",
                  width: "100%",
                }}
              >
                Sign In
              </button>
            </div>
          </form>
        </section>
      </div>
    </div>
  );
}

export default Login;
