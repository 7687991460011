import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import GoalForm from "../components/GoalForm";
import GoalItem from "../components/GoalItem";
import Spinner from "../components/Spinner";
import { getGoals, reset } from "../features/goals/goalSlice";

function Overview() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [showPending, setShowPending] = useState(false);

  const { user } = useSelector((state) => state.auth);
  const { goals, isLoading, isError, message } = useSelector(
    (state) => state.goals
  );
  console.log(goals);
  const pendingAlbums = goals.filter(
    (album) => album.status === "pending approval by team"
  );

  useEffect(() => {
    if (isError) {
      console.log(message);
      //localStorage.clear();
    }

    if (!user) {
      /*setTimeout(() => {
        console.log("World!");
      }, 5000);*/
      navigate("/login");
      console.log("yeetDash");
    }

    if (user && user.role == "admin") {
      dispatch(getGoals());
    }

    return () => {
      dispatch(reset());
    };
  }, [user]);

  if (!user) {
    return <p>Your session has expired. Please log back in.</p>;
  }

  if (user && user.isApproved == "false") {
    return (
      <p>
        Your account must be approved by a moderator before you can upload
        albums.
      </p>
    );
  }

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <div className="dash">

      {!showPending && user && (
        <section className="content">
          <h1>Albums</h1>
          {user.role != "admin" && <h2>Your Albums</h2>}
          {/*user.role == "admin" && (
            <h2
              onClick={() => {
                setShowPending(true);
              }}
              style={{ border: "solid 2px black", cursor: "pointer" }}
            >
              Show Pending Albums Only
            </h2>
          )*/}
          {goals.length > 0 ? (
            <div className="goals">
              {goals.map((goal) => (
                <GoalItem key={goal._id} goal={goal} />
              ))}
            </div>
          ) : (
            <div>
              {user.role != "admin" && <h3>You have not started any albums</h3>}
              {user.role == "admin" && <h3>No albums</h3>}
            </div>
          )}
        </section>
      )}
      {/*showPending && user && user.role == "admin" && (
        <section className="content">
          <h1>Albums</h1>
          {user.role != "admin" && <h2>Your Albums</h2>}
          {user.role == "admin" && (
            <h2
              onClick={() => {
                setShowPending(false);
              }}
              style={{ border: "solid 2px black", cursor: "pointer" }}
            >
              Show All Albums
            </h2>
          )}
          {goals.length > 0 ? (
            <div className="goals">
              {pendingAlbums.map((goal) => (
                <GoalItem key={goal._id} goal={goal} />
              ))}
            </div>
          ) : (
            <div>
              {user.role != "admin" && <h3>You have not started any albums</h3>}
              {user.role == "admin" && <h3>No pending albums</h3>}
            </div>
          )}
        </section>
      )*/}
    </div>
  );
}

export default Overview;
