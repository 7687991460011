import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import GoalForm from "../components/GoalForm";
import GoalItem from "../components/GoalItem";
import Spinner from "../components/Spinner";
import { getGoals, reset } from "../features/goals/goalSlice";
import { logout } from "../features/auth/authSlice";


function Dashboard() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [showPending, setShowPending] = useState(false);

  const { user } = useSelector((state) => state.auth);
  const { goals, isLoading, isError, message } = useSelector(
    (state) => state.goals
  );
  console.log(goals);
  const pendingAlbums = goals.filter(
    (album) => album.status === "pending approval by team"
  );

  const [searchQuery, setSearchQuery] = useState("");
  const [filteredGoals, setFilteredGoals] = useState([]);

  //useEffect to update goals based on search query
  useEffect(() => {
    if(searchQuery != ""){
      setFilteredGoals(
        goals.filter((goal) =>
          goal.text.toLowerCase().includes(searchQuery.toLowerCase())
        )
      );
    }
    else{
      setFilteredGoals(goals);
    }
  }, [searchQuery, goals]);


  useEffect(() => {
    if (isError) {
      console.log(message);
      //localStorage.clear();
      console.log("hello darkness");
      return;
    }

    if (!user) {
      /*setTimeout(() => {
        console.log("World!");
      }, 5000);*/
      navigate("/login");
      console.log("yeetDash");
    }

    // const asyncGetGoals = async () => {
    //   try {
    //     await dispatch(getGoals());
    //     console.log("bruh", goals);
    //   } catch (error) {
    //     console.error(error);
    //     console.log("bruh")
    //     //log out
    //     // dispatch(logout());
    //     // navigate("/login");
    //   }
    // };

    if (user) {
      try {
        dispatch(getGoals());
      } catch (error) {
        console.log("this error");
        console.error(error);
        console.log("this error");
        //log out
        dispatch(logout());
        navigate("/login");
      }
    }

    return () => {
      dispatch(reset());
    };
  }, [user]);

  //useeffect for isError
  useEffect(() => {
    if (isError) {
      dispatch(logout());
      navigate("/login");
    }
  }, [isError]);

  if (!user) {
    return <p>Your session has expired. Please log back in.</p>;
  }

  if (user && user.isApproved == "false") {
    return (
      <p>
        Your account must be approved by a moderator before you can upload
        albums.
      </p>
    );
  }

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <div className="dash">
      {user && user.role != "admin" && (
        <>
          <section className="heading">
            {/*<h5>Welcome {user && user.name}</h5>*/}
            <p>Create an album doc</p>
          </section>
          <GoalForm user={user} />
        </>
      )}

      {!showPending && user && user.role == "admin" && (
        <section className="content">
          <h1>Albums</h1>
          <input
              type="text"
              placeholder="Search for an album"
              onChange={(e) => setSearchQuery(e.target.value)}
            />
          {user.role != "admin" && <div>
            <h2>Your Albums</h2>
            </div>}
          {filteredGoals.length > 0 ? (
            <div className="goals">
              {filteredGoals.map((goal) => (
                <GoalItem key={goal._id} goal={goal} />
              ))}
            </div>
          ) : (
            <div>
              {user.role != "admin" && <h3>You have not started any albums</h3>}
              {user.role == "admin" && <h3>No albums found</h3>}
            </div>
          )}
        </section>
      )}
      {showPending && user && user.role == "admin" && (
        <section className="content">
          <h1>Albums</h1>
          {user.role != "admin" && <h2>Your Albums</h2>}
          {user.role == "admin" && (
            <h2
              onClick={() => {
                setShowPending(false);
              }}
              style={{ border: "solid 2px black", cursor: "pointer" }}
            >
              Show All Albums
            </h2>
          )}
          {goals.length > 0 ? (
            <div className="goals">
              {pendingAlbums.map((goal) => (
                <GoalItem key={goal._id} goal={goal} />
              ))}
            </div>
          ) : (
            <div>
              {user.role != "admin" && <h3>You have not started any albums</h3>}
              {user.role == "admin" && <h3>No pending albums</h3>}
            </div>
          )}
        </section>
      )}
    </div>
  );
}

export default Dashboard;
