import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import GoalForm from "../components/GoalForm";
import GoalItem from "../components/GoalItem";
import Spinner from "../components/Spinner";
import { getGoals, reset } from "../features/goals/goalSlice";

function Albums() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { user } = useSelector((state) => state.auth);
  const { goals, isLoading, isError, message } = useSelector(
    (state) => state.goals
  );

  const [searchQuery, setSearchQuery] = useState("");
  const [filteredGoals, setFilteredGoals] = useState([]);

  //useEffect to update goals based on search query
  useEffect(() => {
    if(searchQuery != ""){
      setFilteredGoals(
        goals.filter((goal) =>
          goal.text.toLowerCase().includes(searchQuery.toLowerCase())
        )
      );
    }
    else{
      setFilteredGoals(goals);
    }
  }, [searchQuery, goals]);

  useEffect(() => {
    if (isError) {
      console.log(message);
      //localStorage.clear();
    }

    if (!user) {
      /*setTimeout(() => {
        console.log("World!");
      }, 5000);*/
      navigate("/login");
      console.log("yeetDash");
    }

    dispatch(getGoals());

    return () => {
      dispatch(reset());
    };
  }, [user]);

  if (!user) {
    return <p>Your session has expired. Please log back in.</p>;
  }

  if (user && user.isApproved == "false") {
    return (
      <p>
        Your account must be approved by a moderator before you can upload
        albums.
      </p>
    );
  }

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <div className="dash">
      <section className="content">
        {user.role != "admin" && <h1>Your Albums</h1>}
        <input
              type="text"
              placeholder="Search for an album"
              onChange={(e) => setSearchQuery(e.target.value)}
            />
        {filteredGoals.length > 0 ? (
          <div className="goals">
            {filteredGoals.map((goal) => (
              <GoalItem key={goal._id} goal={goal} />
            ))}
          </div>
        ) : (
          <div>
            {user.role != "admin" && <h3>No albums found</h3>}
          </div>
        )}
      </section>
    </div>
  );
}

export default Albums;
