import { configureStore } from "@reduxjs/toolkit";
import authReducer from "../features/auth/authSlice";
import goalReducer from "../features/goals/goalSlice";
import songReducer from "../features/songs/songSlice";
import messageReducer from "../features/messages/messageSlice";
import savedArtistReducer from "../features/savedArtists/savedArtistSlice";

export const store = configureStore({
  reducer: {
    auth: authReducer,
    goals: goalReducer,
    songs: songReducer,
    messages: messageReducer,
    savedArtists: savedArtistReducer,
  },
});
