import { deleteGoal, updateGoal } from "../features/goals/goalSlice";
import { createMessage } from "../features/messages/messageSlice";
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getSongs, reset } from "../features/songs/songSlice";
import SongItem from "./SongItem";
import GoalForm from "./GoalForm";
import GoalEditForm from "./GoalEditForm";
import axios from "axios";
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";
import { FaExpandAlt, FaEdit } from "react-icons/fa";
import { ToastContainer, toast } from "react-toastify";
import {
  Document,
  Packer,
  Paragraph,
  Table,
  TableRow,
  TableCell,
  WidthType,
  ExternalHyperlink,
  TextRun,
  TableLayoutType,
} from "docx";
import { saveAs } from "file-saver";

function GoalItem({ goal }) {
  const [status, setStatus] = useState("");

  const [showSongs, setShowSongs] = useState(false);
  const [showMetadata, setShowMetadata] = useState(false);
  const [showArtists, setShowArtists] = useState(false);

  const dispatch = useDispatch();

  const { user } = useSelector((state) => state.auth);
  const { songs, isLoading, isError, message } = useSelector(
    (state) => state.songs
  );

  const filteredSongs = songs.filter((song) => song.albumId === goal.albumId);
  filteredSongs.sort(
    (a, b) => parseInt(a.songNumber, 10) - parseInt(b.songNumber, 10)
  );

  // filter goal artists by role
  const primaryArtists = goal.artists.filter(
    (artist) => artist.role == "Primary" || artist.role == "primary"
  );
  const featuredArtists = goal.artists.filter(
    (artist) => artist.role == "Featured" || artist.role == "featured"
  );

  const onSubmit = async (e) => {
    e.preventDefault();
    /*dispatch(
      updateGoal(
        {
          status: status,
        },
        goal._id
      )
    );*/
    const config = {
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    };
    axios.put("/api/goals/" + goal._id, { status: status }, config);
    /*const userId = goal.user.id;
    const email = goal.email;
    const name = goal.name;
    const message = status;*/
    /*dispatch(
      createMessage({
        userId,
        email,
        name,
        message,
      })
    );*/
    const messageData = {
      userId: goal.user, // the ID of the user that the message is for
      email: "temp",
      name: "temp",
      messageStr: status,
      album: goal.text,
    };
    axios.post("/api/messages/", messageData, config);

    axios
      .post("/send-email", {
        to: goal.accountEmail,
        subject: `A status update is available for your album ${goal.text}`,
        text: `A status update is available for your album ${goal.text}: ${status}`,
      })
      .then((res) => console.log(res.data))
      .catch((err) => console.error(err));
    window.location.reload(false);
  };

  useEffect(() => {
    dispatch(getSongs());

    return () => {
      dispatch(reset());
    };
  }, [user]);

  function formatDate(date) {
    let day = date.getDate().toString().padStart(2, "0");
    let month = (date.getMonth() + 1).toString().padStart(2, "0"); // getMonth() is zero-based
    let year = date.getFullYear();
    return `${month}/${day}/${year}`;
  }

  const handleDownload = async () => {
    let contractSection = [];
    if (goal.contractDriveLink != "N/A") {
      contractSection = [
        new TableRow({
          children: [
            new TableCell({
              children: [new Paragraph("Contract(s):")],
              width: {
                size: 4505,
                type: WidthType.DXA,
              },
            }),
            new TableCell({
              children: [
                new Paragraph({
                  children: [
                    new ExternalHyperlink({
                      children: [
                        new TextRun({
                          text: `${goal.contractDriveLink}`,
                          style: "Hyperlink",
                        }),
                      ],
                      link: `${goal.contractDriveLink}`,
                    }),
                  ],
                }),
              ],
              width: {
                size: 4505,
                type: WidthType.DXA,
              },
            }),
          ],
        }),
      ];
    }

    // Create a table with rows and cells
    const table = new Table({
      columnWidths: [4505, 4505],
      rows: [
        new TableRow({
          children: [
            new TableCell({
              children: [new Paragraph("Album Name:")],
              width: {
                size: 4505,
                type: WidthType.DXA,
              },
            }),
            new TableCell({
              children: [new Paragraph(`${goal.text}`)],
              width: {
                size: 4505,
                type: WidthType.DXA,
              },
            }),
          ],
        }),
        new TableRow({
          children: [
            new TableCell({
              children: [new Paragraph("Single/EP/Album:")],
              width: {
                size: 4505,
                type: WidthType.DXA,
              },
            }),
            new TableCell({
              children: [
                new Paragraph(
                  goal.recordType ? `${goal.recordType}` : "single"
                ),
              ],
              width: {
                size: 4505,
                type: WidthType.DXA,
              },
            }),
          ],
        }),
        new TableRow({
          children: [
            new TableCell({
              children: [new Paragraph("UPC:")],
              width: {
                size: 4505,
                type: WidthType.DXA,
              },
            }),
            new TableCell({
              children: [new Paragraph(`${goal.upc}`)],
              width: {
                size: 4505,
                type: WidthType.DXA,
              },
            }),
          ],
        }),
        ...primaryArtists.map((artist, index) => {
          return new TableRow({
            children: [
              new TableCell({
                children: [new Paragraph(`Album Artist ${index + 1}:`)],
                width: {
                  size: 4505,
                  type: WidthType.DXA,
                },
              }),
              new TableCell({
                children: [
                  new Paragraph({
                    children: [
                      new TextRun({
                        text: `${artist.artistName} (`,
                      }),
                      new ExternalHyperlink({
                        children: [
                          new TextRun({
                            text: "spotify",
                            style: "Hyperlink",
                          }),
                        ],
                        link: `${artist.spotifyURI}`,
                      }),
                      new TextRun({
                        text: `) (`,
                      }),
                      new ExternalHyperlink({
                        children: [
                          new TextRun({
                            text: "apple music",
                            style: "Hyperlink",
                          }),
                        ],
                        link: `${artist.appleId}`,
                      }),
                      new TextRun({
                        text: `)${artist.soundCloud ? " (" : ""}`,
                      }),
                      new ExternalHyperlink({
                        children: [
                          new TextRun({
                            text: artist.soundCloud ? "soundcloud" : "",
                            style: "Hyperlink",
                          }),
                        ],
                        link: `${artist.soundCloud}`,
                      }),
                      new TextRun({
                        text: `${artist.soundCloud ? ")" : ""}`,
                      }),
                    ],
                    width: {
                      size: 4505,
                      type: WidthType.DXA,
                    },
                  }),
                ],
              }),
            ],
          });
        }),

        new TableRow({
          children: [
            new TableCell({
              children: [new Paragraph(`Featured Artists:`)],
              width: {
                size: 4505,
                type: WidthType.DXA,
              },
            }),
            new TableCell({
              children: [
                new Paragraph({
                  children: featuredArtists.flatMap((artist) => [
                    new TextRun({
                      text: `${artist.artistName} (`,
                    }),
                    new ExternalHyperlink({
                      children: [
                        new TextRun({
                          text: "spotify",
                          style: "Hyperlink",
                        }),
                      ],
                      link: `${artist.spotifyURI}`,
                    }),
                    new TextRun({
                      text: `) (`,
                    }),
                    new ExternalHyperlink({
                      children: [
                        new TextRun({
                          text: "apple music",
                          style: "Hyperlink",
                        }),
                      ],
                      link: `${artist.appleId}`,
                    }),
                    new TextRun({
                      text: `)${artist.soundCloud ? " (" : ""}`,
                    }),
                    new ExternalHyperlink({
                      children: [
                        new TextRun({
                          text: artist.soundCloud ? "soundcloud" : "",
                          style: "Hyperlink",
                        }),
                      ],
                      link: `${artist.soundCloud}`,
                    }),
                    new TextRun({
                      text: `${artist.soundCloud ? ")" : ""}`,
                    }),
                    new TextRun({
                      text: `${
                        artist == featuredArtists[featuredArtists.length - 1]
                          ? ""
                          : ", "
                      }`,
                    }),
                  ]),
                  width: {
                    size: 4505,
                    type: WidthType.DXA,
                  },
                }),
              ],
            }),
          ],
        }),

        new TableRow({
          children: [
            new TableCell({
              children: [new Paragraph("Number of Tracks:")],
              width: {
                size: 4505,
                type: WidthType.DXA,
              },
            }),
            new TableCell({
              children: [new Paragraph(`${goal.numTracks}`)],
              width: {
                size: 4505,
                type: WidthType.DXA,
              },
            }),
          ],
        }),
        new TableRow({
          children: [
            new TableCell({
              children: [new Paragraph("Cover Art:")],
              width: {
                size: 4505,
                type: WidthType.DXA,
              },
            }),
            new TableCell({
              children: [
                new Paragraph({
                  children: [
                    new ExternalHyperlink({
                      children: [
                        new TextRun({
                          text: `${goal.imageDriveLink}`,
                          style: "Hyperlink",
                        }),
                      ],
                      link: `${goal.imageDriveLink}`,
                    }),
                  ],
                }),
              ],
              width: {
                size: 4505,
                type: WidthType.DXA,
              },
            }),
          ],
        }),
        ...contractSection,
        new TableRow({
          children: [
            new TableCell({
              children: [new Paragraph("Original Release Date:")],
              width: {
                size: 4505,
                type: WidthType.DXA,
              },
            }),
            new TableCell({
              children: [
                new Paragraph(
                  `${
                    goal.releaseDateAsap
                      ? "ASAP"
                      : formatDate(new Date(goal.releaseDate))
                  }`
                ),
              ],
              width: {
                size: 4505,
                type: WidthType.DXA,
              },
            }),
          ],
        }),
        new TableRow({
          children: [
            new TableCell({
              children: [new Paragraph("Live Date:")],
              width: {
                size: 4505,
                type: WidthType.DXA,
              },
            }),
            new TableCell({
              children: [
                new Paragraph(
                  `${
                    goal.liveDateAsap
                      ? "ASAP"
                      : formatDate(new Date(goal.liveDate))
                  }`
                ),
              ],
              width: {
                size: 4505,
                type: WidthType.DXA,
              },
            }),
          ],
        }),
        new TableRow({
          children: [
            new TableCell({
              children: [new Paragraph("Primary Genre:")],
              width: {
                size: 4505,
                type: WidthType.DXA,
              },
            }),
            new TableCell({
              children: [new Paragraph(`${goal.primaryGenre}`)],
              width: {
                size: 4505,
                type: WidthType.DXA,
              },
            }),
          ],
        }),
        new TableRow({
          children: [
            new TableCell({
              children: [new Paragraph("Secondary Genre:")],
              width: {
                size: 4505,
                type: WidthType.DXA,
              },
            }),
            new TableCell({
              children: [new Paragraph(`${goal.secondaryGenre}`)],
              width: {
                size: 4505,
                type: WidthType.DXA,
              },
            }),
          ],
        }),
        new TableRow({
          children: [
            new TableCell({
              children: [new Paragraph("Label Name:")],
              width: {
                size: 4505,
                type: WidthType.DXA,
              },
            }),
            new TableCell({
              children: [new Paragraph(`${goal.labelName}`)],
              width: {
                size: 4505,
                type: WidthType.DXA,
              },
            }),
          ],
        }),
        new TableRow({
          children: [
            new TableCell({
              children: [new Paragraph("C Line:")],
              width: {
                size: 4505,
                type: WidthType.DXA,
              },
            }),
            new TableCell({
              children: [new Paragraph(`${goal.cLine}`)],
              width: {
                size: 4505,
                type: WidthType.DXA,
              },
            }),
          ],
        }),
        new TableRow({
          children: [
            new TableCell({
              children: [new Paragraph("P Line:")],
              width: {
                size: 4505,
                type: WidthType.DXA,
              },
            }),
            new TableCell({
              children: [new Paragraph(`${goal.pLine}`)],
              width: {
                size: 4505,
                type: WidthType.DXA,
              },
            }),
          ],
        }),
        // Add more rows as necessary
      ],
      layout: TableLayoutType.FIXED,
    });

    // Create a document and add the table to it
    const doc = new Document({
      sections: [
        {
          children: [
            new Paragraph("The System Distribution Form:"),
            table,
            new Paragraph(""),
            ...filteredSongs.map((song, index) => {
              let primarySongArtists = song.artists.filter(
                (artist) => artist.role == "Primary" || artist.role == "primary"
              );
              let featuredSongArtists = song.artists.filter(
                (artist) =>
                  artist.role == "Featured" || artist.role == "featured"
              );
              return new Table({
                columnWidths: [4505, 4505],
                rows: [
                  new TableRow({
                    children: [
                      new TableCell({
                        children: [new Paragraph(`Track #:`)],
                        width: { size: 50, type: WidthType.PERCENTAGE },
                      }),
                      new TableCell({
                        children: [new Paragraph(`${index + 1}`)],
                        width: { size: 50, type: WidthType.PERCENTAGE },
                      }),
                    ],
                  }),
                  new TableRow({
                    children: [
                      new TableCell({
                        children: [new Paragraph(`Track Name:`)],
                        width: { size: 50, type: WidthType.PERCENTAGE },
                      }),
                      new TableCell({
                        children: [new Paragraph(`${song.name}`)],
                        width: { size: 50, type: WidthType.PERCENTAGE },
                      }),
                    ],
                  }),
                  ...primarySongArtists.map((artist, index) => {
                    return new TableRow({
                      children: [
                        new TableCell({
                          children: [new Paragraph(`Artist ${index + 1}:`)],
                          width: { size: 50, type: WidthType.PERCENTAGE },
                        }),
                        new TableCell({
                          children: [
                            new Paragraph({
                              children: [
                                new TextRun({
                                  text: `${artist.artistName} (`,
                                }),
                                new ExternalHyperlink({
                                  children: [
                                    new TextRun({
                                      text: "spotify",
                                      style: "Hyperlink",
                                    }),
                                  ],
                                  link: `${artist.spotifyURI}`,
                                }),
                                new TextRun({
                                  text: `) (`,
                                }),
                                new ExternalHyperlink({
                                  children: [
                                    new TextRun({
                                      text: "apple music",
                                      style: "Hyperlink",
                                    }),
                                  ],
                                  link: `${artist.appleId}`,
                                }),
                                new TextRun({
                                  text: `)${artist.soundCloud ? " (" : ""}`,
                                }),
                                new ExternalHyperlink({
                                  children: [
                                    new TextRun({
                                      text: artist.soundCloud ? "soundcloud" : "",
                                      style: "Hyperlink",
                                    }),
                                  ],
                                  link: `${artist.soundCloud}`,
                                }),
                                new TextRun({
                                  text: `${artist.soundCloud ? ")" : ""}`,
                                }),
                              ],
                            }),
                          ],
                          width: { size: 50, type: WidthType.PERCENTAGE },
                        }),
                      ],
                    });
                  }),

                  // add featured artists
                  new TableRow({
                    children: [
                      new TableCell({
                        children: [new Paragraph(`Featured Artists:`)],
                        width: {
                          size: 4505,
                          type: WidthType.DXA,
                        },
                      }),
                      new TableCell({
                        children: [
                          new Paragraph({
                            children: featuredSongArtists.flatMap((artist) => [
                              new TextRun({
                                text: `${artist.artistName} (`,
                              }),
                              new ExternalHyperlink({
                                children: [
                                  new TextRun({
                                    text: "spotify",
                                    style: "Hyperlink",
                                  }),
                                ],
                                link: `${artist.spotifyURI}`,
                              }),
                              new TextRun({
                                text: `) (`,
                              }),
                              new ExternalHyperlink({
                                children: [
                                  new TextRun({
                                    text: "apple music",
                                    style: "Hyperlink",
                                  }),
                                ],
                                link: `${artist.appleId}`,
                              }),
                              new TextRun({
                                text: `)${artist.soundCloud ? " (" : ""}`,
                              }),
                              new ExternalHyperlink({
                                children: [
                                  new TextRun({
                                    text: artist.soundCloud ? "soundcloud" : "",
                                    style: "Hyperlink",
                                  }),
                                ],
                                link: `${artist.soundCloud}`,
                              }),
                              new TextRun({
                                text: `${artist.soundCloud ? ")" : ""}`,
                              }),
                              new TextRun({
                                text: `${
                                  artist == featuredSongArtists[featuredSongArtists.length - 1]
                                    ? ""
                                    : ", "
                                }`,
                              }),
                            ]),
                            width: {
                              size: 4505,
                              type: WidthType.DXA,
                            },
                          }),
                        ],
                      }),
                    ],
                  }),

                  // add writer credits row joining the writers
                  new TableRow({
                    children: [
                      new TableCell({
                        children: [new Paragraph(`Writer Credits:`)],
                        width: { size: 50, type: WidthType.PERCENTAGE },
                      }),
                      new TableCell({
                        children: [
                          new Paragraph(
                            `${song.writers
                              .map((writer) => writer.writerName)
                              .join(", ")}`
                          ),
                        ],
                        width: { size: 50, type: WidthType.PERCENTAGE },
                      }),
                    ],
                  }),
                  // do same for producers
                  new TableRow({
                    children: [
                      new TableCell({
                        children: [new Paragraph(`Production Credits:`)],
                        width: { size: 50, type: WidthType.PERCENTAGE },
                      }),
                      new TableCell({
                        children: [
                          new Paragraph(
                            `${song.producers
                              .map((producer) => producer.producerName)
                              .join(", ")}`
                          ),
                        ],
                        width: { size: 50, type: WidthType.PERCENTAGE },
                      }),
                    ],
                  }),
                  // add audio link
                  new TableRow({
                    children: [
                      new TableCell({
                        children: [new Paragraph(`Track File:`)],
                        width: { size: 50, type: WidthType.PERCENTAGE },
                      }),
                      new TableCell({
                        children: [
                          new Paragraph({
                            children: [
                              new ExternalHyperlink({
                                children: [
                                  new TextRun({
                                    text: `${song.audioDriveLink}`,
                                    style: "Hyperlink",
                                  }),
                                ],
                                link: `${song.audioDriveLink}`,
                              }),
                            ],
                          }),
                        ],
                        width: { size: 50, type: WidthType.PERCENTAGE },
                      }),
                    ],
                  }),
                  // add isrc
                  new TableRow({
                    children: [
                      new TableCell({
                        children: [new Paragraph(`ISRC:`)],
                        width: { size: 50, type: WidthType.PERCENTAGE },
                      }),
                      new TableCell({
                        children: [new Paragraph(`${song.isrc}`)],
                        width: { size: 50, type: WidthType.PERCENTAGE },
                      }),
                    ],
                  }),
                  // add explicit
                  new TableRow({
                    children: [
                      new TableCell({
                        children: [new Paragraph(`Explicit (Y/N):`)],
                        width: { size: 50, type: WidthType.PERCENTAGE },
                      }),
                      new TableCell({
                        children: [
                          new Paragraph(`${song.explicit ? "Y" : "N"}`),
                        ],
                        width: { size: 50, type: WidthType.PERCENTAGE },
                      }),
                    ],
                  }),
                  // language of lyrics
                  new TableRow({
                    children: [
                      new TableCell({
                        children: [new Paragraph(`Language of Lyrics:`)],
                        width: { size: 50, type: WidthType.PERCENTAGE },
                      }),
                      new TableCell({
                        children: [new Paragraph(`${song.language}`)],
                        width: { size: 50, type: WidthType.PERCENTAGE },
                      }),
                    ],
                  }),
                  // Tiktok Start Time (XX:XX):
                  new TableRow({
                    children: [
                      new TableCell({
                        children: [new Paragraph(`Tiktok Start Time (XX:XX):`)],
                        width: { size: 50, type: WidthType.PERCENTAGE },
                      }),
                      new TableCell({
                        children: [new Paragraph(`${song.previewStartTime}`)],
                        width: { size: 50, type: WidthType.PERCENTAGE },
                      }),
                    ],
                  }),
                ],
                width: { size: 100, type: WidthType.PERCENTAGE },
                layout: TableLayoutType.FIXED,
              });
            }),
          ],
        },
      ],
    });

    // Generate the .docx file and trigger download
    const blob = await Packer.toBlob(doc);
    // save as Artist Name(s) - Release Name - The System / Warner Distribution
    saveAs(
      blob,
      `${goal.artists.map((artist) => artist.artistName).join(", ")} - ${
        goal.text
      } - The System / Warner Distribution.docx`
    );
  };

  return (
    <div className="goal">
      <ToastContainer />
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-evenly",
        }}
      >
        {/*<div>{new Date(goal.createdAt).toLocaleString("en-US")}</div>*/}
        <div>
          <h2>Album Title: {goal.text}</h2>
          {user && user.role == "admin" && (
            <>
              <h4 style={{ fontWeight: "normal" }}>
                <b>Account Username:</b>
                <br /> {goal.accountName}
              </h4>
              <h4 style={{ fontWeight: "normal" }}>
                <b>Account Email:</b>
                <br /> {goal.accountEmail}
              </h4>
            </>
          )}
          <Popup
            modal
            nested
            trigger={
              <button
                className="close2"
                type="button"
                style={{
                  fontSize: "30px",
                  background: "none",
                  //border: "solid 1px",
                  //margin: "5px 5px 5px 5px",
                  cursor: "pointer",
                  borderRadius: "3px",
                }}
              >
                <FaEdit />
              </button>
            }
            position="right center"
            //className="my-popup"
          >
            <div className="">
              <p>Edit Album</p>
              <GoalEditForm goal={goal} />
            </div>
          </Popup>
          <br />
          <button
            onClick={() => {
              if (showArtists) {
                setShowArtists(false);
              } else {
                setShowArtists(true);
              }
            }}
          >
            Show Artists
          </button>
          {showArtists && (
            <>
              {" "}
              <h3>Album Artists: </h3>
              {goal.artists &&
                goal.artists[0] &&
                goal.artists.map((art) => (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                    }}
                  >
                    <h3 key={art.artistName}>{art.artistName} </h3>
                    <Popup
                      trigger={
                        <button
                          type="button"
                          style={{
                            fontSize: "15px",
                            background: "none",
                            border: "solid 1px",
                            margin: "5px 5px 5px 5px",
                            cursor: "pointer",
                            borderRadius: "3px",
                          }}
                        >
                          Show Artist Details
                          <FaExpandAlt />
                        </button>
                      }
                      position="right center"
                      //className="my-popup"
                    >
                      <div className="">
                        <p>
                          <span style={{ fontWeight: "bold" }}>
                            Artist Name:
                          </span>{" "}
                          {art.artistName}
                        </p>
                        <p>
                          <span style={{ fontWeight: "bold" }}>
                            Artist Role:
                          </span>{" "}
                          {art.role}
                        </p>
                        <p>
                          <span style={{ fontWeight: "bold" }}>
                            Spotify URI:
                          </span>{" "}
                          {art.spotifyURI}
                        </p>
                        <p>
                          <span style={{ fontWeight: "bold" }}>Apple ID:</span>{" "}
                          {art.appleId}
                        </p>
                      </div>
                    </Popup>
                  </div>
                ))}
            </>
          )}
          <br />
          <button
            onClick={() => {
              if (showMetadata) {
                setShowMetadata(false);
              } else {
                setShowMetadata(true);
              }
            }}
          >
            Show Metadata
          </button>
          {showMetadata && (
            <>
              <h3>
                Record Type: {goal.recordType ? goal.recordType : "single"}
              </h3>
              <h3>Writers:</h3>
              {goal.writers.length == 0 && <h4>None</h4>}
              {goal.writers &&
                goal.writers[0] &&
                goal.writers.map((writer) => (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                    }}
                  >
                    <h4 key={writer.writerName}>{writer.writerName} </h4>
                  </div>
                ))}
              <h3>Producers:</h3>
              {goal.producers.length == 0 && <h4>None</h4>}
              {goal.producers &&
                goal.producers[0] &&
                goal.producers.map((producer) => (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                    }}
                  >
                    <h4 key={producer.producerName}>
                      {producer.producerName}{" "}
                    </h4>
                  </div>
                ))}
              {user && user.role && (
                <>
                  <h4>
                    Release Date:{" "}
                    {goal.releaseDateAsap
                      ? "ASAP"
                      : goal.releaseDate.toString().substr(0, 10)}
                  </h4>
                  <h4>
                    Live Date:{" "}
                    {goal.liveDateAsap ? "ASAP" : goal.liveDate.substr(0, 10)}
                  </h4>
                  <h4>© Line: {goal.cLine}</h4>
                  <h4>℗ Line: {goal.pLine}</h4>
                  <h4>Label Name: {goal.labelName}</h4>
                  <h4>UPC: {goal.upc}</h4>
                  <h4>Primary Genre: {goal.primaryGenre}</h4>
                  <h4>Secondary Genre: {goal.secondaryGenre}</h4>
                </>
              )}
            </>
          )}
          <br />
          <button
            onClick={() => {
              if (showSongs) {
                setShowSongs(false);
              } else {
                setShowSongs(true);
              }
            }}
          >
            Show Tracklist
          </button>
          {showSongs && (
            <>
              <h3>Songs: </h3>
              <div className="songs">
                {filteredSongs.map((song) => (
                  <SongItem key={song._id} song={song} goal={goal} />
                ))}
              </div>
            </>
          )}
          <br />
          <button onClick={handleDownload}>Download Docx</button>
          <h4>
            Live Date:{" "}
            {goal.liveDateAsap ? "ASAP" : goal.liveDate.substr(0, 10)}
          </h4>
        </div>

        {/* <div style={{}}>
          <h3
            style={{
              color:
                goal.status == "pending approval by team"
                  ? "#b5a000"
                  : goal.status == "Album rejected."
                  ? "red"
                  : goal.status == "Album approved!"
                  ? "green"
                  : "black",
            }}
          >
            Status: {goal.status}
          </h3>
          {user && user.role == "admin" && (
            <section className="form">
              <h4>Update status:</h4>
              <button
                className="btn btn-submit"
                type="button"
                style={{ marginTop: "20px", width: "50%" }}
                onClick={async () => {
                  //setStatus("Album approved!");
                  const config = {
                    headers: {
                      Authorization: `Bearer ${user.token}`,
                    },
                  };
                  axios.put(
                    "/api/goals/" + goal._id,
                    { status: "Album approved!" },
                    config
                  );
                  const messageData = {
                    userId: goal.user, // the ID of the user that the message is for
                    email: "temp",
                    name: "temp",
                    messageStr: status,
                    album: goal.text,
                  };
                  console.log(messageData);
                  axios.post(
                    "/api/messages/",
                    {
                      userId: goal.user,
                      email: "temp",
                      name: "temp",
                      messageStr: "Album approved!",
                      album: goal.text,
                    },
                    config
                  );
                  axios
                    .post("/send-email", {
                      to: goal.accountEmail,
                      subject: `A status update is available for your album ${goal.text}`,
                      text: `A status update is available for your album ${goal.text}: Album approved!`,
                    })
                    .then((res) => console.log(res.data))
                    .catch((err) => console.error(err));
                  window.location.reload(false);
                }}
              >
                Approve
              </button>
              <button
                className="btn btn-submit"
                type="button"
                style={{ marginTop: "20px", width: "50%" }}
                onClick={async () => {
                  //setStatus("Album rejected.");
                  const config = {
                    headers: {
                      Authorization: `Bearer ${user.token}`,
                    },
                  };
                  axios.put(
                    "/api/goals/" + goal._id,
                    { status: "Album rejected." },
                    config
                  );
                  const messageData = {
                    userId: goal.user, // the ID of the user that the message is for
                    email: "temp",
                    name: "temp",
                    messageStr: "Album rejected.",
                    album: goal.text,
                  };
                  console.log(messageData);
                  axios.post(
                    "/api/messages/",
                    {
                      userId: goal.user,
                      email: "temp",
                      name: "temp",
                      messageStr: "Album rejected.",
                      album: goal.text,
                    },
                    config
                  );
                  axios
                    .post("/send-email", {
                      to: goal.accountEmail,
                      subject: `A status update is available for your album ${goal.text}`,
                      text: `A status update is available for your album ${goal.text}: Album rejected.`,
                    })
                    .then((res) => console.log(res.data))
                    .catch((err) => console.error(err));
                  window.location.reload(false);
                }}
              >
                Reject
              </button>
              <form onSubmit={onSubmit}>
                <div className="form-container">
                  <div className="form-group">
                    <label htmlFor="text">Send custom status message:</label>
                    <input
                      type="text"
                      name="text"
                      id="text"
                      value={status}
                      onChange={(e) => {
                        setStatus(e.target.value);
                      }}
                    />
                    <button
                      className="btn btn-submit"
                      type="submit"
                      style={{ marginTop: "20px", width: "50%" }}
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </form>
            </section>
          )}
        </div> */}
      </div>

      {user && user.email == "admin@thesystemrecords.com" && (
        <button
          onClick={() => {
            dispatch(deleteGoal(goal._id));
          }}
          className="close"
        >
          X
        </button>
      )}
    </div>
  );
}

export default GoalItem;
