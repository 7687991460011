import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import savedArtistService from "./savedArtistService";

const initialState = {
  savedArtists: [],
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
};

// Create new saved artist
export const createSavedArtist = createAsyncThunk(
  "savedartists/create",
  async (savedArtistData, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token;
      return await savedArtistService.createSavedArtist(savedArtistData, token);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Get user saved artists
export const getSavedArtists = createAsyncThunk(
  "savedartists/getAll",
  async (_, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token;
      return await savedArtistService.getSavedArtists(token);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Delete saved artist
export const deleteSavedArtist = createAsyncThunk(
  "savedartists/delete",
  async (id, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token;
      return await savedArtistService.deleteSavedArtist(id, token);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Update new goal
// Don't think this is working :(
/*
export const updateGoal = createAsyncThunk(
  "goals/update",
  async (goalData, id, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token;
      return await goalService.updateGoal(goalData, id, token);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      return thunkAPI.rejectWithValue(message);
    }
  }
);
*/

export const savedArtistSlice = createSlice({
  name: "savedArtist",
  initialState,
  reducers: {
    reset: (state) => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(createSavedArtist.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(createSavedArtist.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.savedArtists.push(action.payload);
      })
      .addCase(createSavedArtist.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(getSavedArtists.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getSavedArtists.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.savedArtists = action.payload;
      })
      .addCase(getSavedArtists.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(deleteSavedArtist.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deleteSavedArtist.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.savedArtists = state.savedArtists.filter(
          (savedArtist) => savedArtist._id !== action.payload.id
        );
      })
      .addCase(deleteSavedArtist.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      });
  },
});

export const { reset } = savedArtistSlice.actions;
export default savedArtistSlice.reducer;
